import { useEffect, useState, useMemo } from "react";
import { Marker, Popup } from "react-leaflet";
import { useSelector } from "react-redux";
import L from "leaflet";
import useWS from "../../hooks/useWS";
import lightningIcon1 from "../../assets/lightning_m_1.svg";
import lightningIcon2 from "../../assets/lightning_m_2.svg";
import lightningIcon3 from "../../assets/lightning_m_3.svg";

import { LightningData, StrikeData } from "../../interfaces/lightning";
import { RootState } from "../../store"
import { debounce} from "lodash";
import "./Lightning.css";
import { v4 as uuidv4, v1 as uuidv1 } from "uuid";

const lightningIconInstance1 = new L.Icon({
  iconUrl: lightningIcon1,          // Icon image URL (you can still use an image if needed)
  iconSize: new L.Point(30, 30),   // Size of the icon
  iconAnchor: new L.Point(12.5, 12.5), // Center the icon
});

const lightningIconInstance2 = new L.Icon({
  iconUrl: lightningIcon2,          // Icon image URL (you can still use an image if needed)
  iconSize: new L.Point(30, 30),   // Size of the icon
  iconAnchor: new L.Point(12.5, 12.5), // Center the icon
});
const lightningIconInstance3 = new L.Icon({
  iconUrl: lightningIcon3,          // Icon image URL (you can still use an image if needed)
  iconSize: new L.Point(30, 30),   // Size of the icon
  iconAnchor: new L.Point(12.5, 12.5), // Center the icon
});

const pulseBG = new L.divIcon({
    className: 'pulse-icon', // Optional for additional styles
    html: '<div class="pulsating-marker"></div>', // The div with the pulse animation
    iconSize: [30, 30], // Size of the marker icon
    iconAnchor: [12.5, 12.5], // Anchor the icon in the center
});

const MAX_MARKERS = 500;  // Set a maximum number of markers
// The maximum distance from the user's location to query for lightning data
// might be a good parameter to put in the user settings using a slider or dropdown and be 
// limited to a minimum of maybe 50mi (80km) and a maximum of maybe 200mi (321km)

const Lightning = () => {
  const token = localStorage.getItem("token");
  const url = `wss://test.truweathersolutions.com/websocket?token=${token}`;
  const location = useSelector((state: RootState) => state.user.defaultLocation);
  const [lightningData, setLightningData] = useState<LightningData[]>([]);
  const [subscribed, setSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);
  const { messages, status, sendMessage, reconnect, disconnect } = useWS(url);  
  const radius = 100

  const connectMessage = useMemo(() => ({
    message_type: "connect",
    dataFeed: "lightning",
    filterType: "point",
    lat: Number(location.latitude),
    long: Number(location.longitude),
    radius: radius,
  }), [location]);

  const disconnectMessage = useMemo(() => ({
    message_type: "disconnect",
    dataFeed: "lightning",
    filterType: "point",
    lat: location.latitude,
    long: location.longitude,
    radius: radius,
  }), [location]);

  useEffect(() => {
    setSubscribed(false);  // Reset subscription state when component mounts
  }, []);

  // Subscribing to lightning data
  useEffect(() => {
    if (status === "open" && !subscribed) {
      sendMessage(JSON.stringify(connectMessage));
      setSubscribed(true);
      setLoading(true);  // Set loading to true when subscribing
    }
  }, [status, subscribed, connectMessage, sendMessage]);

  useEffect(() => {
    if (status === "open") {
      // Send disconnect message for the old subscription
      if (subscribed) {
        sendMessage(JSON.stringify(disconnectMessage));
      }
      // Send connect message for the new subscription
      sendMessage(JSON.stringify(connectMessage));
      setSubscribed(true);
      setLoading(true);
    }
  }, [status, location, radius, sendMessage]);  

  // Reset the subscription when WebSocket closes or encounters an error
  useEffect(() => {
    if (status === "closed" || status === "error") {
      setSubscribed(false);  // Reset subscribed so it can re-subscribe on reconnect
    }
  }, [status]);

  // Cleanup WebSocket when component is unmounted
  useEffect(() => {
    return () => {
      disconnect();  // Ensure disconnect is called when the component unmounts
    };
  }, [disconnect]);

  // Handle incoming messages with debouncing
  const debouncedUpdateData = useMemo(() => {
    return debounce((newData: any) => { // debounce from lodash
      setLightningData((prevData) => {
        const updatedData = [...prevData, ...newData].reduce((acc, data) => {
          const exists = acc.find(
            (d:StrikeData) =>
              d.ob.timestamp === data.time &&
              d.loc.lat === data.loc.lat&&
              d.loc.long === data.loc.long
          );
          if (!exists) acc.push(data);

          return acc.filter((currObj: any) => {
            const currentTime = new Date();
            const strikeTime = new Date(currObj.ob.dateTimeISO)
            const differenceInMs = currentTime - strikeTime;
            const msInOneMinute = 1000 * 60;
            const differenceInMinutes = Math.floor(differenceInMs / msInOneMinute);
            if(differenceInMinutes > 10) return false
            else return true
          });
        }, [] as LightningData[]);

        // If the number of markers exceeds the limit, remove the oldest
        if (updatedData.length > MAX_MARKERS) {
          return updatedData.slice(-MAX_MARKERS);  // Keep only the most recent MAX_MARKERS
        }
        return updatedData;
      });
      
      setLoading(false);  // Stop loading when data is received
    }, 200);  // Debounce to update every 200ms
  }, []);

  function getOpacity(dateTimeISO: any): number | undefined {
    const currentTime = new Date();
    const strikeTime = new Date(dateTimeISO)
    const differenceInMs = currentTime - strikeTime;
    const msInOneMinute = 1000 * 60;
    const differenceInMinutes = Math.floor(differenceInMs / msInOneMinute);
    
    if(differenceInMinutes <= 2) return 1.0;
    else if(differenceInMinutes > 2 && differenceInMinutes <= 5) return 0.25;
    else return 0.1;
  }

  function getIcon(dateTimeISO: any){
    const currentTime = new Date();
    const strikeTime = new Date(dateTimeISO)
    const differenceInMs = currentTime - strikeTime;
    const msInOneMinute = 1000 * 60;
    const differenceInMinutes = Math.floor(differenceInMs / msInOneMinute);
    
    if(differenceInMinutes <= 2) return lightningIconInstance1;
    else if(differenceInMinutes > 2 && differenceInMinutes <= 5) return lightningIconInstance2;
    else return lightningIconInstance3;
  }

  // useEffect(() => {
  //   console.log(lightningData);
  // },[lightningData]);

  useEffect(() => {
    const newMessages = messages.filter((msg) => msg.msg_type === "data");
    if (newMessages.length > 0) {
      debouncedUpdateData(newMessages);
    }
  }, [messages, debouncedUpdateData]);

  return (
    <>
      {loading && <div className="loading-indicator">Loading Lightning Data...</div>}
      
      {!loading && lightningData.map((data) => {
        if (data['msg_type'] == "data") {
          

          // Now TypeScript knows 'data' is 'StrikeData'
          return (
            <>
            <Marker
              key={uuidv1()}
              position={[parseFloat(data.loc.lat), parseFloat(data.loc.long)]}
              icon={pulseBG}
            >
            </Marker>
            <Marker
              key={uuidv4()}
              position={[parseFloat(data.loc.lat), parseFloat(data.loc.long)]}
              icon={getIcon(data.ob.dateTimeISO)}
              opacity={getOpacity(data.ob.dateTimeISO)}
            >
              <Popup className="custom-popup">
                <div className="bg-white shadow-md rounded my-6 w-full">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="p-3 border-b-2 border-gray-200 bg-gray-100 text-gray-600 text-left text-sm uppercase font-normal">
                          Property
                        </th>
                        <th className="py-3 border-b-2 border-gray-200 bg-gray-100 text-gray-600 text-left text-sm uppercase font-normal">
                          Value
                        </th>
                      </tr>     
                    </thead>
                    <tbody>
                      <tr>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                          Time
                        </td>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                          {new Date(data.ob.dateTimeISO).toLocaleString()}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                          Type
                        </td>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                          {data.ob.pulse.type.toUpperCase()}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                          Latitude
                        </td>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                          {data.loc.lat}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                          {data.loc.long}
                        </td>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                          {new Date(data.ob.dateTimeISO).toLocaleString()}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                        Peak Current
                        </td>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                        {data.ob.pulse.peakamp}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                        IC Height
                        </td>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                        {data.ob.pulse.icHeightM === null ? "N/A" : data.ob.pulse.icHeightM }
                        </td>
                      </tr>
                      <tr>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                        Number of Sensors
                        </td>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                        {data.ob.pulse.numSensors === null ? "N/A" : data.ob.pulse.numSensors}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                        Damage Potential
                        </td>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                        {data.damagePotential === null ? "N/A" : data.damagePotential}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                        Ellipse
                        </td>
                        <td className="p-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                        {data.ellipse === null ? "N/A" : data.ellipse}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Popup>
            </Marker>
            
          </>
          );
        } else {
          return null;
        }
      
      })}
    </>
  );
};

export default Lightning;
