// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
import marker from '../../assets/location.svg'
import signpost from '../../assets/signpost.svg'
import aviation from '../../assets/aviationweather.svg'
import maps from '../../assets/maps.svg'
import forecaster from '../../assets/call.svg'
import addLocation from '../../assets/add-location.svg'
import alert from '../../assets/alert.svg'
import radar from '../../assets/radar.svg'
import observation from '../../assets/observation.svg'
import areaweather from '../../assets/areaweather.svg'
import { ReactNode } from 'react'

/**
 * PLEASE READ
 * To add a menu item programatically, use the appendMenuItem action or the
 * prependMenuItem action.
 *
 * const item = {
 *   id: "an-id-that-will-be-used-for-this-action", // Required.
 *   label: "My Menu Item", // optional. ( for top-most menu item in heirarchy )
 *   link: 'https://www.google.com', //optional.
 *   icon: bigIcon, // optional.
 *   callback: verySpecialCallback({name: loc_name, lat: loc_lat, lng: loc_lng}), // optional. Use any extraData you need.
 *   multiSelect: false, // optional.
 *   subMenu: [], // required.
 * }
 * dispatch(appendMenuItem(item, parentMenuId))
 */


export interface IMenuItem {
  id: string
  label?: string | null
  link?: string | null
  icon?: string | null
  multiSelect?: boolean | null
  active?: boolean | null
  subMenu: Array<IMenuItem>
  callback?: () => void
  specialComponent?: ReactNode
  extraData?: any
  disabled?: boolean
  toggle?: boolean
}

export const sideMenuItems: Array<IMenuItem> = [
  {
    id: 'side-menu-root',
    label: '',
    link: '',
    icon: null,
    active: false,
    multiSelect: false,
    subMenu: [
      {
        id: 'location-menu',
        label: 'Locations',
        link: '/location',
        icon: marker,
        active: false,
        multiSelect: false,
        subMenu: [
          {
            id: 'add-location',
            label: 'Add new location',
            link: '/',
            icon: addLocation,
            active: false,
            multiSelect: false,
            subMenu: [],
          },
        ],
      },
      {
        id: 'alerts-menu',
        label: 'Alerts',
        link: '/alerts',
        icon: alert,
        active: false,
        multiSelect: false,
        disabled: false,
        subMenu: [
          {
            id: 'alerts-create',
            label: 'Create an Alert',
            link: '#',
            icon: null,
            active: false,
            multiSelect: false,
            subMenu: [],
          },
        ],
      },
      {
        id: 'decision-products-menu',
        label: 'Decision Products',
        link: '/decision-products',
        icon: signpost,
        active: false,
        subMenu: [
          {
            id: 'decision-missioncast',
            label: 'MissionCast',
            link: '#',
            icon: null,
            active: false,
            subMenu: [],
          },
          {
            id: 'decision-routcase',
            label: 'RouteCast',
            link: '#',
            icon: null,
            active: false,
            disabled: false,
            subMenu: [],
          },
        ],
      },
      {
        id: 'radar-menu',
        label: 'Radar',
        link: '/radar',
        icon: radar,
        active: false,
        multiSelect: false,
        subMenu: [
          {
            id: 'observations-mrms-radar',
            label: 'Current Radar',
            link: '#',
            icon: null,
            active: false,
            multiSelect: false,
            subMenu: [],
            disabled: false,
          },
          {
            id: 'observations-myradar',
            label: 'MyRadar NowCAST (US Only)',
            link: '#',
            icon: null,
            active: false,
            multiSelect: false,
            subMenu: [],
            disabled: false,
          },
        ],
      },
      {
        id: 'observations-menu',
        label: 'Observations',
        link: '/observations',
        icon: observation,
        active: false,
        multiSelect: false,
        subMenu: [
          {
            id: 'surface-observations-menu',
            label: 'Surface Observations',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [
              // {
              //   id: 'surface-obs-us-menu',
              //   label: 'US',
              //   link: '#',
              //   icon: null,
              //   active: false,
              //   multiSelect: false,
              //   subMenu: [],
              // },
              // {
              //   id: 'surface-obs-ca-menu',
              //   label: 'Canada',
              //   link: '#',
              //   icon: null,
              //   active: false,
              //   multiSelect: false,
              //   subMenu: [],
              // },
              // {
              //   id: 'surface-obs-uk-menu',
              //   label: 'UK',
              //   link: '#',
              //   icon: null,
              //   active: false,
              //   multiSelect: false,
              //   subMenu: [],
              // },
            ],
          },
          {
            id: 'camera-network',
            label: 'Camera Network',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [],
          },
          {
            id: 'vertical-profile',
            label: 'Vertical Profile',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [],
          },
          {
            id: 'observations-lightning',
            label: 'Lightning',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [],
          },
        ],
      },
      {
        id: 'area-weather-menu',
        label: 'Area Weather',
        link: '/areaweather',
        icon: areaweather,
        active: false,
        multiSelect: false,
        subMenu: [
          {
            id: 'weather-menu',
            label: 'Current Area Weather',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [
              {
                id: 'weather-us-menu',
                label: 'US',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [
                  {
                    id: 'aviation-ceiling',
                    label: 'Cloud Ceiling',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-visibility',
                    label: 'Visibility',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-temperature',
                    label: 'Temperature',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-surface-winds',
                    label: 'Surface Winds',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-winds-80m',
                    label: '80m Winds',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-wind-gust',
                    label: 'Wind Gust',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  }
                ],
              },
              {
                id: 'weather-ca-menu',
                label: 'Canada',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [
                  {
                    id: 'aviation-temperature-ca',
                    label: 'Temperature (CA)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-ceiling-ca',
                    label: 'Ceiling (CA)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-visibility-ca',
                    label: 'Visibility (CA)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-windgust-ca',
                    label: 'Wind Gust (CA)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-surface-winds-ca',
                    label: 'Surface Winds (CA)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                ],
              },
              {
                id: 'weather-uk-menu',
                label: 'Western Europe',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                disabled: false,
                subMenu: [
                  {
                    id: 'aviation-surface-winds-uk',
                    label: 'Surface Winds (Western Europe)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-winds-80m-uk',
                    label: '80m Winds (Western Europe)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-temperature-uk',
                    label: 'Temperature (Western Europe)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-visibility-uk',
                    label: 'Visibility (Western Europe)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-ceiling-uk',
                    label: 'Ceiling (Western Europe)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-wind-gust-uk',
                    label: 'Wind Gust (Western Europe)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  }
                ],
              },
              {
                id: 'weather-central-eu-menu',
                label: 'Central Europe',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [
                  {
                    id: 'aviation-ceiling-ce',
                    label: 'Cloud Ceiling (CE)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-visibility-ce',
                    label: 'Visibility (CE)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-temperature-ce',
                    label: 'Temperature (CE)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-surface-winds-ce',
                    label: 'Surface Winds (CE)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-winds-80m-ce',
                    label: '80m Winds (CE)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-wind-gust-ce',
                    label: 'Wind Gust (CE)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                ],
              },
              {
                id: 'weather-middle-east-menu',
                label: 'Middle East',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [
                  {
                    id: 'aviation-ceiling-middle-east',
                    label: 'Cloud Ceiling (Middle East)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-visibility-middle-east',
                    label: 'Visibility (Middle East)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-temperature-middle-east',
                    label: 'Temperature (Middle East)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-surface-winds-middle-east',
                    label: 'Surface Winds (Middle East)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-winds-80m-middle-east',
                    label: '80m Winds (Middle East)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-wind-gust-middle-east',
                    label: 'Wind Gust (Middle East)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                ],
              },
              {
                id: 'weather-br-menu',
                label: 'Sao Paulo',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [
                  {
                    id: 'aviation-temperature-br',
                    label: 'Temperature (BR)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-ceiling-br',
                    label: 'Ceiling (BR)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-visibility-br',
                    label: 'Visibility (BR)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-windgust-br',
                    label: 'Wind Gust (BR)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-surface-winds-br',
                    label: 'Surface Winds (BR)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                  {
                    id: 'aviation-winds-80m-br',
                    label: '80m Winds (BR)',
                    link: '#',
                    icon: null,
                    active: false,
                    multiSelect: false,
                    subMenu: [],
                  },
                ],
              }
            ],
          },
          {
            id: 'forecast-weather-menu',
            label: 'Forecast Weather',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [
              {
                id: 'forecast-aviation-ceiling',
                label: 'Cloud Ceiling',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'forecast-aviation-visibility',
                label: 'Visibility',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'forecast-aviation-temperature',
                label: 'Temperature',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'forecast-aviation-surface-winds',
                label: 'Surface Winds',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'forecast-aviation-winds-80m',
                label: '80m Winds',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'forecast-aviation-wind-gust',
                label: 'Wind Gust',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
            ],
          },
        ],
      },
      {
        id: 'aviation-menu',
        label: 'Aviation Weather',
        link: '/aviation',
        icon: aviation,
        active: false,
        multiSelect: false,
        subMenu: [
          {
            id: 'aviation-hazards',
            label: 'Hazards',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [
              {
                id: 'aviation-pirep',
                label: 'PIREP',
                link: '#',
                icon: null,
                active: false,
                multiSelect: true,
                subMenu: [],
              },
              {
                id: 'aviation-airmet',
                label: 'AIRMET',
                link: '#',
                icon: null,
                active: false,
                multiSelect: true,
                subMenu: [],
              },
              {
                id: 'aviation-sigmet',
                label: 'SIGMET',
                link: '#',
                icon: null,
                active: false,
                multiSelect: true,
                subMenu: [],
              },
            ],
          },
          {
            id: 'aviation-metar',
            label: 'METAR',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [],
          },
          {
            id: 'aviation-taf',
            label: 'TAF',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [],
          },
          {
            id: 'aviation-wafs-icing',
            label: 'WAFS-Icing',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [],
          },
          {
            id: 'aviation-wafs-turbulence',
            label: 'WAFS-Turbulence',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [],
          },
          
        ],
      },
      {
        id: 'maps-menu',
        label: 'Maps',
        link: '/maps',
        icon: maps,
        active: false,
        multiSelect: false,
        subMenu: [
          {
            id: 'maps-road',
            label: 'Road',
            link: 'test2',
            icon: null,
            active: false,
            multiSelect: false,
            subMenu: [],
          },
          {
            id: 'maps-satellite',
            label: 'Satellite',
            link: '#',
            icon: null,
            active: false,
            multiSelect: false,
            subMenu: [],
          },
          {
            id: 'maps-sectional',
            label: 'Aeronautical',
            link: '#',
            icon: null,
            active: false,
            multiSelect: false,
            subMenu: [],
            disabled: false,
          },
        ],
      },
      {
        id: 'forecaster-menu',
        label: 'Live Forecaster',
        link: '/forecaster',
        icon: forecaster,
        active: false,
        multiSelect: false,
        disabled: true,
        subMenu: [
          // {
          //   id: 'forecaster-call',
          //   label: 'Call/Video',
          //   link: '#',
          //   icon: null,
          //   active: false,
          //   multiSelect: false,
          //   subMenu: [],
          // },
        ],
      },
      {
        id: 'dark-mode-menu',
        label: 'Dark Mode',
        link: '#',
        icon: null,
        active: false,
        multiSelect: false,
        disabled: false,
        subMenu: []
      },
    ],
  },
]
