const MapPopupTable = ({ properties }) => {
  const capitalizeStr = (str) => {    
    const caps = str?.charAt(0).toUpperCase() + str?.slice(1)
    return caps
  }
  let keys: string[] = []
  const tableData = Object.entries(properties).map(([key, value], index) => {
    keys.push(key)
    if (Object.keys(properties).includes('Raw TAF') && key != 'Unit') {
      if (Object.keys(properties['Unit']).includes(key)) {
        return {
          key,
          value,
          units: properties['Unit'][key],
        }
      }
      return {
        key,
        value,
      }
    } else if (
      properties?.['Report Type'] &&
      properties?.['Report Type'].value == 'METAR'
    ) {
      return { key, value: value.value, units: value.unit }
    } else if (
      (properties?.data && properties.data == 'AIRMET') ||
      properties.airSigmetType == 'SIGMET' ||
      properties.data == 'AIREP'
    ) {
      if (key == 'altitudeLow1') {
        return { key, value: value.toString() }
      } else {
        return { key, value: value }
      }
    } else if (
      properties.airepType
    ) {
      if (key == 'altitudeLow1') {
        return { key, value: value.toString() }
      } else {
        return { key, value: value.value }
      }
    } else if (properties.Data?.StationID || properties) {
      if (!key.startsWith('CloudLayer')) {
        return { key, value: value.value, units: value.units }
      } else {
        let heightAGL = null
        if (typeof value.value == 'string') {
          return { key, value: value.value, units: null }
        }

        if (value.value.HeightAGL === null) {
          if (value.value.SkyCondition === 'clear') {
            //value.value.SkyCondition
            heightAGL = capitalizeStr(value.value.SkyCondition) //value.value.HeightAGL
          } else {
            value.value.SkyCondition.charAt(0).toUpperCase() +
              value.value.SkyCondition.slice(1)
            heightAGL =
              capitalizeStr(value.value.SkyCondition) + ' at ' + '20000 FT' //value.value.HeightAGL
          }
        } else {
          if (value.value.SkyCondition === 'clear') {
            heightAGL = capitalizeStr(value.value.SkyCondition) //value.value.HeightAGL
          } else if (value.value === 'No Ceilometer') {
            return { key, value: value.value, units: value.units }
          } else {
            const val = value?.value?.HeightAGL?.value ?? "NA"
            const units = value?.value?.HeightAGL?.units ?? "NA"
            heightAGL = `${capitalizeStr(value.value.SkyCondition)} at ${
              typeof value === 'string' ? val.toString() : val
            } ${units}`
          }
        }
        return { key, value: heightAGL, units: value.units }
      }
    } else {
      return { key, value: 'N/A' }
    }
  })
  const order: string[] = [
    'Data',
    'timestamp',
    'Issue Time',
    'Valid Time From',
    'Valid Time To',
    'Valid Time',
    'Site',
    'STID',
    'ID',
    'Cloud Coverage 1',
    'Cloud Coverage 2',
    'Cloud Coverage 3',
    'Cloud Cover',
    'CloudLayer1',
    'CloudLayer2',
    'CloudLayer3',
    'Cloud Layer 1 Value',
    'Cloud Layer 2 Value',
    'Cloud Layer 3 Value',
    'WindSpeed',
    'Wind Speed',
    'Wind Direction',
    'WindDirection',
    'Visibility',
    'Temperature',
    'Dew Point Temperature',
    'DewpointTemperature',
    'Pressure',
    'Sea Level Pressure',
    'Raw TAF',
    'Raw Observation',
  ]
  keys = keys.filter((value) => !order.includes(value))
  const finalTableData = []
  for (const key of order) {
    const value = tableData.find((item) => item.key === key)
    if (value) {
      finalTableData.push(value)
    }
  }
  for (const key of keys) {
    const value = tableData.find((item) => item.key === key)
    if (value) {
      finalTableData.push(value)
    }
  }
  
  function displayLabel(key: string): string | undefined {
    const labels: { [key: string]: string } = {
        "SkyConditionCloudLayerCovers": "Sky Condition",
        "SkyConditionCloudLayerHeights": "Cloud Base Height",
        "CloudThickness": "Cloud Thickness",
    };
    return labels[key];  
  }

  function layerMapping(key: string): string | undefined {
    const labels: { [key: string]: string } = {
        "0": "CLR",
        "1": "FEW",
        "2": "FEW",
        "3": "SCT",
        "4": "SCT",
        "5": "BKN",
        "6": "BKN",
        "7": "BKN",
        "8": "OVC"
    };
    return labels[key];  
  }

  return (
    <>
      {finalTableData.some(obj => obj.key === "SensorType" && obj.value === "CL61") ?
        <div className="bg-white shadow-md rounded my-6 w-full">
          {/*  className="leading-normal" */}
          <table className="w-full">
            <thead>
                <th  colspan="2" className="w-full p-3 border-b-2 border-gray-200 bg-gray-100 text-gray-600 text-left text-sm uppercase font-normal">
                  Lidar Data
                </th>
            </thead>
            <tbody>
              {finalTableData.map(
                  (item, index) =>
                    item.value !== undefined &&
                    item.value !== '' &&
                    item.value !== 'N/A' &&
                    item.value !== 'NONE' &&
                    (item.key == "timestamp") && (
                      <tr key={index}>
                        <td className="py-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                          {capitalizeStr(item.key)}
                        </td>
                        <td className="py-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                          {(typeof item.value === 'string' || (typeof item.value === 'number' && item.value >= 0)) ? item.value : "-"}{' '}

                          {item.value !== null || item.value >= 0 ? item.units : ''}
                        </td>
                      </tr>
                    )
              )}
            <tr className="border-t">
              <td colSpan="2" className="py-3">
                {/* Nested Table */}
                <table className="min-w-full table-auto bg-gray-50 border border-gray-300">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="px-4 py-2 text-left text-sm text-gray-700">Property</th>
                      <th className="px-4 py-2 text-left text-sm text-gray-700">Layer 1</th>
                      <th className="px-4 py-2 text-left text-sm text-gray-700">Layer 2</th>
                      <th className="px-4 py-2 text-left text-sm text-gray-700">Layer 3</th>
                      <th className="px-4 py-2 text-left text-sm text-gray-700">Layer 4</th>
                      <th className="px-4 py-2 text-left text-sm text-gray-700">Layer 5</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalTableData.map(
                    (item, index) =>
                      item.value !== undefined &&
                      item.value !== '' &&
                      item.value !== 'N/A' &&
                      item.value !== 'NONE' &&
                      (item.key !== "StationID" && item.key !== "timestamp" && item.key !== "SensorType" && item.key !== "VerticalVisibility" 
                        && item.key !== "SkyConditionTotalCloudCover" && item.key !== "Precipitation" && item.key !== "Fog" && item.key !== "CloudHeights"
                      ) && (
                        <tr key={index} className="border-t">
                          <td className="p-2 border-gray-200 bg-white text-sm">{displayLabel(item.key)}</td>
                          {
                            item.value.map((data:any) => (
                              <td className="p-2 text-sm">{item.key == "SkyConditionCloudLayerCovers" ? (data >= 0 ? layerMapping(data) : "-") : (data >= 0 ? data + " " + item.units: "-")  }</td>
                            ))
                          }
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
            {finalTableData.map(
                  (item, index) =>
                    item.value !== undefined &&
                    item.value !== '' &&
                    item.value !== 'N/A' &&
                    item.value !== 'NONE' &&
                    (item.key == "StationID" || item.key == "SensorType" || item.key == "VerticalVisibility" || item.key == "Precipitation"  || item.key == "Fog" ) && (
                      <tr key={index}>
                        <td className="py-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                          {capitalizeStr(item.key)}
                        </td>
                        <td className="py-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                          {(typeof item.value === 'string' || (typeof item.value === 'number' && item.value >= 0)) ? item.value : "-"}{' '}

                          {item.value !== null || item.value >= 0 ? item.units : ''}
                        </td>
                      </tr>
                    )
              )}
            </tbody>   
          </table>   
        </div>
        :
        <div className="bg-white shadow-md rounded my-6">
          {/*  className="leading-normal" */}
          <table className="w-full">
            <thead>
              <tr>
                <th className="py-3 border-b-2 border-gray-200 bg-gray-100 text-gray-600 text-left text-sm uppercase font-normal">
                  Property
                </th>
                <th className="py-3 border-b-2 border-gray-200 bg-gray-100 text-gray-600 text-left text-sm uppercase font-normal">
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              {finalTableData.map(
                (item, index) =>
                  item.value !== undefined &&
                  item.value !== '' &&
                  item.value !== 'N/A' &&
                  item.value !== 'NONE' && (
                    <tr key={index}>
                      <td className="py-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                        {item.key}
                      </td>
                      <td className="py-3 border-b break-words w-1 border-gray-200 bg-white text-sm">
                        {item.value !== null ? item.value : `No ${item.key}`}{' '}

                        {item.value !== null ? item.units : ''}
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      }
    </>
  )
}

export default MapPopupTable
